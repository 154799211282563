/* Forma 36 CSS vars */
@import '@contentful/f36-tokens/dist/css/index.css';

/* Custom SASS vars */
$global-transition: all .2s ease-in-out;

/**
 * General
 */
html.fouc body * {
  visibility: hidden;
  opacity: 0;
}

html,
body {
  color: var(--color-black);
  padding: 0;
  margin: 0;
  font-size: 100%;
  font-family: var(--font-stack-primary);
  line-height: var(--line-height-default);
}

body {
  padding-bottom: 1rem;
}

* {
  box-sizing: border-box;
}

a {
  color: var(--color-primary);
  font-weight: var(--font-weight-medium);
  transition: all var(--transition-duration-default) var(--transition-easing-default);
  text-decoration: none;

  &:hover {
    color: var(--color-blue-dark);
    text-decoration: underline;
    cursor: pointer;

    .link-icon {
      fill: var(--color-blue-dark);
    }
  }

  &[data-test-id="cf-ui-button"]:hover {
    text-decoration: none;
  }
}

.link-icon {
  fill: var(--color-primary);
  transition: $global-transition;
  cursor: pointer;

  &:hover {
    fill: var(--color-blue-dark);
  }
}

blockquote {
  margin: 0;
  padding: 1em 1em 1em 1.5em;
  font-size: .875rem;
  background: var(--gray-300);
  border-left: 6px solid var(--gray-400);

  @media screen and (max-width: 599px) {
    blockquote {
      padding-left: 1.25em;
    }
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

/* Fix for rich text renderer putting <p> tags in <li>'s' */
li p {
  margin: 0;
}

/* Set font size to stop auto-zoom on iOs */
input,
select,
textarea {
  font-size: 1rem !important;
}

hr {
  margin: 3rem 0;
  border: 0;
  border-top: 1px solid var(--gray-300);

  @media screen and (max-width: 599px) {
    margin: 2rem 0;
  }
}

span.italic {
  font-style: italic;
}

#nprogress .spinner {
  display: none !important;
  visibility: hidden !important;
}

.article--rich-text {
  u {
    text-decoration: none;
    background: yellow;
  }

  > ul > li:nth-child(n+2),
  > ol > li:nth-child(n+2) {
    margin-top: 1rem;
  }

  ol ol > li {
    margin-top: 1rem;
    list-style-type: lower-alpha;
  }

  ul ul > li {
    margin-top: 1rem;
    list-style-type: circle;
  }

  .entry-preview & {
    > ul > li:nth-child(n+2),
    > ol > li:nth-child(n+2) {
      margin-top: 0;
    }
  }
}

.ghost-button {
  border: 0 !important;
  background: none !important;
  
  &:hover,
  &:focus,
  &:active {
    border: 0 !important;
    background: none !important;
    box-shadow: none !important;
  }

  &.ghost-button--small {
    padding: 0 !important;
  }
}

article[data-test-id="cf-ui-note"] {
  @media screen and (max-width: 599px) {
    padding: .75rem;
    column-gap: .5rem;
  }
}

h2, h3 {
  &.anchor {
    cursor: pointer;
  }
}

.minimal-source {
  display: none !important;
}

@media only screen {
  .clean-view {
    .main-header {
      > button:first-of-type {
        display: none !important;
      }

      h1 {
        padding-left: 1.5rem;
      }
    }
  }
  
  .minimal-view {
    .minimal-source {
      margin-top: 0;
      display: block !important;
      color: var(--gray-500);
      font-size: .825rem;
      line-height: 1;
    }

    .main-header,
    ~ footer,
    .page-actions,
    .cross-reference,
    .entry-nav,
    .detailed-link svg,
    .unit-profile__details--points,
    .unit-profile__details--option {
      display: none !important;
    }

    .unit-profile__details--optional-rules {
      display: block !important;
    }

    .main-content {
      padding: 0 !important;

      > div {
        padding-bottom: 0 !important;
        max-width: none !important;
      }

      h1 {
        font-size: 2rem;
      }

      h2 {
        font-size: 1.5rem;
      }

      h3 {
        font-size: 1.25rem;
      }
    }

    .additional-links {
      padding-bottom: 0 !important;
      border-bottom: 0 !important;
    }

    table,
    table tr,
    table th,
    table td {
      background: none !important;
      border-color: var(--gray-600) !important;
    }
  }
}



/**
 * Typography
 */
h1,
.h1 {
  font-size: var(--font-size-4xl);
  line-height: 1.1;
}

h2,
.h2 {
  font-size: var(--font-size-3xl);
  line-height: 1.1;
}

h3,
.h3 {
  font-size: var(--font-size-2xl);
  line-height: 1.1;
}

h4,
.h4 {
  font-size: var(--font-size-xl);
}

h5,
.h5 {
  font-size: var(--font-size-l);
}

h6,
.h6 {
  font-size: var(--font-size-m);
}

@media screen and (max-width: 599px) {
  h1,
  .h1 {
    font-size: var(--font-size-3xl);
  }

  h2,
  .h2 {
    font-size: var(--font-size-2xl);
  }

  h3,
  .h3 {
    font-size: var(--font-size-xl);
  }

  h4,
  .h4 {
    font-size: var(--font-size-l);
  }
}

.entry-preview,
.chapter-view {
  h1,
  h1 {
    font-size: var(--font-size-2xl);
  }

  h2,
  h2 {
    font-size: var(--font-size-xl);
  }

  h3,
  h3 {
    font-size: var(--font-size-xl);
  }

  h4,
  h4 {
    font-size: var(--font-size-l);
  }

  .spell-list,
  .magic-item-list {
    margin-top: 1rem;
  }

  .spell-list .spell:nth-of-type(n+2),
  .magic-item-list .spell:nth-of-type(n+2) {
    margin-top: 2rem;
    padding-top: 1rem;
  }

  .magic-item__header,
  .spell__header,
  .rule-cost {
    font-size: var(--font-size-l);
  }

  .detailed-link svg {
    display: none !important;
  }
}



/**
 * Helpers
 */
.margin-top--none {
  margin-top: 0 !important;
}

.global-notice {
  @media print {
    display: none !important;
  }
}

.global-notice ~ .global-notice {
  margin-top: 1rem;
}

.global-notice article > :first-child {
  margin-top: 0;
}

.global-notice article > :last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 600px) {
  .missing-404 {
    margin-top: 2rem;
  }
}

.header-link {
  margin-right: .25em;

  ~ .copy-link {
    .copy-note {
      @media screen and (min-width: 600px) {
        left: calc(100% + .35em);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

a.header-link {
  color: inherit;
  font-weight: inherit;
}



/**
 * Header / Nav
 */
.main-header {
  h1 {
    margin-bottom: 0;
    line-height: 1.1;
    cursor: pointer;
  
    @media screen and (max-width: 799px) {
      font-size: 1.125rem;
    }

    @media screen and (max-width: 599px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 399px) {
      .index-wap & {
        font-size: .875rem;
      }
    }

    a,
    a:hover,
    a:focus,
    a:active {
      color: inherit;
      font-weight: inherit;
      text-decoration: none;
    }
  }

  > button:first-of-type {
    max-width: 48px;
    border-left: 0;
    border-top: 0;
    border-bottom: 0;

    @media screen and (max-width: 599px) {
      max-width: 30px;
    }
  }
}

.main-actions {
  margin-left: auto;
  padding-left: .5rem;
  display: flex;

  @media screen and (max-width: 599px) {
    margin-right: -.5rem;
  }

  .nav-icon {
    padding: 0 !important;
    margin-right: .75rem !important;
    min-width: 0;

    @media screen and (max-width: 599px) {
      margin-right: .5rem !important;
    }
  }

  .nav-icon:last-of-type {
    margin-right: 0 !important;
  }
}

.nav-header {
  margin: .5rem 0;
  line-height: 1.1;
  text-transform: uppercase;

  &:nth-of-type(n+2) {
    margin-top: .5rem;
  }

  .nav-header__section {
    font-weight: bold;
    font-size: 1rem;
  }

  .nav-header__section a {
    color: inherit;
    font-weight: inherit;
  }

  .nav-icon--direct {
    transform: rotate(90deg);
  }
}



/**
 * Main
 */
.main-content > div {
  padding-bottom: 4rem;
  max-width: 960px !important;

  .full-width & {
    max-width: none !important;
  }
}

.page-title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.copy-note {
  font-size: .75rem;
  color: var(--gray-600);
  font-style: italic;
  font-weight: 500;
  white-space: nowrap;
  transition: $global-transition;
  position: absolute;
  right: 0;
  visibility: hidden;
  opacity: 0;

  &.show {
    visibility: visible;
    opacity: 1;
  }
}

.page-actions {
  margin-left: 1rem;
  display: flex;
  align-items: flex-start;
  position: relative;
  font-size: 0;

  @media screen and (min-width: 600px) {
    top: .5rem;
  }

  .copy-note {
    font-size: .75rem;
    right: 0;
    top: calc(100% + 5px);
  }

  .page-actions__button {
    width: 2.25rem;
    border-radius: 0;
    border-color: var(--gray-300);

    @media screen and (max-width: 599px) {
      width: 2rem;
      height: 2rem;
    }

    &.page-actions__button--report {
      border-left: 0;
    }

    > span {
      padding: 0 .5rem;

      @media screen and (max-width: 599px) {
        padding: 0 .325rem;
      }
    }

    svg {
      fill: var(--gray-700) !important;
    }
  }

  .icon--bug {
    vertical-align: middle;
    width: 16px !important;
    height: 16px !important;
  
    @media screen and (max-width: 599px) {
      width: 14px !important;
      height: 14px !important;
    }
  }
}

.copy-link {
  position: relative;
  display: inline-block;

  @media screen and (max-width: 599px) {
    float: right;
  }

  @media print {
    display: none;
  }

  button {
    min-height: 0;
  }

  button svg {
    fill: var(--gray-700);
  }

  .copy-note {
    top: calc(100% - 8px);
  }

  h3 & {
    top: 4px;
  }
}



/**
 * Footer
 */
footer {
  padding: .5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: var(--gray-900);
  color: #fff;
  left: 0;
  bottom: 0;
  font-size: .75rem;
  text-align: center;

  @media screen and (max-width: 499px) {
    font-size: .625rem;
  }

  a {
    display: inline-block;
    margin: 0 1.5rem;
    color: var(--gray-300);
    line-height: 1.2;

    @media screen and (max-width: 699px) {
      margin: 0 1rem;
    }

    @media screen and (max-width: 599px) {
      margin: 0 .75rem;
    }

    @media screen and (max-width: 499px) {
      margin: 0 .625rem;
    }

    @media screen and (max-width: 399px) {
      margin: 0 .5rem;
    }

    &:hover {
      color: #fff;
    }
  }  

  .hide-for-small {
    @media screen and (max-width: 499px) {
      display: none !important;
    }
  }
}



/**
 * Copyright
 */
.copyright {
  margin-top: 8rem;
  font-size: .625rem;
  line-height: 1.2;

  @media screen and (max-width: 599px) {
    margin-top: 4rem;
  }
}



/**
 * Modal Navigation
 */
.modal-nav {

  .nav-list {
    @media screen and (max-width: 399px) {
      padding-left: 0;

      li {
        list-style: none;
        line-height: 1.1;

        &:nth-child(n+2) {
          margin-top: .5rem;
        }
      }
    }
  }

  .index-list {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    column-count: 2;

    @media screen and (max-width: 799px) {
      column-count: auto;
      column-gap: 0;
    }
  }
}

div[data-test-id="cf-ui-modal-header"] {
  button,
  button:hover,
  button:active,
  button:focus {
    background: none;
    padding: 0;
    border: 0 !important;
    box-shadow: none !important;
  }
}



/**
 * Index Page
 */
.index-section__icon {
  margin-left: 1rem;
  vertical-align: middle;
}

.index-list {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 0;
  padding-bottom: 2rem;
  column-count: 3;
  column-gap: 1rem;
  border-bottom: 1px solid var(--gray-300);

  @media screen and (max-width: 799px) {
    column-count: 2;
  }

  @media screen and (max-width: 499px) {
    column-count: auto;
  }

  li {
    list-style: none;
  }

  &.index-list--expanded > li:nth-child(n+2) {
    margin-top: 1rem;
  }
}

.main-index {
  margin-top: 3rem;

  .index-list-by-association {
    padding-left: 2rem;
    border-left: 1px solid var(--gray-300);
    border-bottom: 1px solid var(--gray-300);
  }

  .index-list-by-association .index-list {
    padding-bottom: 0;  
    border-bottom: 0;
  }

  > *:last-child .index-list {
    margin-bottom:  0;
    padding-bottom:  0;
    border-bottom: 0;
  }

  .toc-section {
    &.no-entries {
      .index-list {
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }

  #main-rulebook {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }
}

.section-divider {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 0;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--gray-300);
}



/**
 * Preferences
 */
.preferences {
  margin-top: 1rem;
  margin-bottom: 1rem;

  > *:nth-child(n+2) {
    margin-top: .5rem;
  }

  label {
    font-size: 15px;
    font-weight: 500;

    svg {
      top: 5px !important;
      width: 14px !important;
      height: 14px !important;
    }
  }
}



/**
 * Search
 */
.search-separator {
  margin: 2rem 0;
  border: 0;
  border-bottom: 1px solid var(--gray-300);
}

.search-results {
  position: relative;

  .index-list {
    column-count: auto;
    column-gap: 0;
    padding-bottom: 0;
    border-bottom: 0;

    &.loading {
      opacity: .3;
    }

    .detailed-link {
      display: block;

      a {
        white-space: normal;
      }
    }
  }

  .breadcrumb {
    color: var(--gray-500);
  }

  .spinner {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.search__advanced > :first-child {
  max-width: 300px;
}

.search__advanced--switches {
  margin-top: 1rem;
  margin-bottom: 1rem;

  > *:nth-child(n+2) {
    margin-top: .5rem;

    @media screen and (max-width: 499px) {
      margin-top: .75rem;
    }
  }

  label {
    font-size: 15px;
    font-weight: 500;

    svg {
      top: 5px !important;
      width: 14px !important;
      height: 14px !important;
    }
  }
}

.search__advanced--submit {
  margin-bottom: 1rem;
}

.search-form__input-wrapper {
  position: relative;

  .inner {
    flex: 1;
    position: relative;
  }
}

.search-form__submit {
  padding: 0 0 0 .5em !important;
}

.search-form__clear {
  position: absolute;
  z-index: 9999;
  top: .5rem;
  right: .5rem;
  transform: rotate(45deg);
  opacity: .5;
  transition: $global-transition;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}



/**
 * Entries / Rules
 */
.editor-actions {
  margin: 1rem 0;
  display: flex;
  align-items: center;

  > * {
    margin-right: 1rem !important;
  }

  div[data-test-id="cf-ui-badge"] span {
    text-transform: uppercase;
  }
}

.breadcrumb {
  margin-bottom: 2rem;
  padding-left: 0;
  font-size: .825rem;
  line-height: 1.3;

  ~ .breadcrumb {
    margin-top: -2rem;
  }

  @media screen and (max-width: 599px) {
    line-height: 1.3
  }

  li {
    list-style: none;
    display: inline-block;
    margin-right: .75rem;
    padding-right: .75rem;
    position: relative;

    @media screen and (max-width: 599px) {
      margin-right: 0;
      padding-right: 0;
      display: block;
    }

    &:after {
      content: '::';
      position: absolute;
      right: 0;
      transform: translateX(50%);

      @media screen and (max-width: 599px) {
        display: none;
      }
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &.update-timestamp,
    &.page-reference {
      margin-right: 0;
      padding-right: 0;
      display: block;
      color: var(--gray-500);
      font-style: italic;
    }

    &.update-timestamp:after,
    &.page-reference:after {
      display: none;
    }
  }
}

.section-intro {
  color: var(--gray-500);
  font-style: italic;

  em,
  i {
    font-style: normal;
  }
}

.linked-rules {
  margin-bottom: 1.75rem;
  font-weight: bold;
  font-style: italic;
  line-height: 1.2;

  @media screen and (max-width: 399px) {
    margin-bottom: 1rem;
  }
}

.rule-cost {
  font-size: var(--font-size-2xl);
  font-weight: bold;
  line-height: 1.2;

  @media screen and (max-width: 599px) {
    font-size: var(--font-size-xl);
    display: block;
  }

  @media screen and (max-width: 399px) {
    font-size: 1.125rem;
  }
}

.embedded-asset {
  max-width: 600px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 649px) {
    max-width: 100%;
  }

  img {
    margin: 0 auto;
    max-width: 100%;
    display: block;
  }

  figcaption {
    width: 100%;
    padding: .75em 1.25em;
    font-style: italic;
    font-size: .875rem;
    text-align: left;
    background: var(--gray-300);
  }
}

.additional-links {
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--gray-300);
}

.section-link ~ .related-links {
  margin-top: 1rem;
}

.section-link__header {
  margin-right: .5rem;
  font-weight: 700;
}

.related-links .index-list {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;

  .entry-preview & {
    margin-bottom: 1rem;
  }
}

.related-links__header {
  margin-right: .325rem;
  font-weight: 700;
}

.detailed-link {
  display: inline-block;

  &.italic {
    font-style: italic;
  }

  a {
    white-space: nowrap;
  }

  svg {
    @media print {
      display: none;
    }
  }
}

.deep-link__icon {
  cursor: pointer;
  margin-left: .325rem;
  vertical-align: text-bottom;
  transform: rotate(180deg) scaleX(-1);
  transition: $global-transition;
}

.cross-reference {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 1px solid var(--gray-300);
  background: var(--gray-100);

  @media print {
    display: none !important;
  }

  body.dark-mode & {
    background: var(--gray-900);
    border-color: var(--gray-700);
  }
}

.cross-reference__toggle {
  padding: .75rem .75rem .75rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 599px) {
    padding: .25rem .25rem .25rem 1rem;
  }
}

.cross-reference__links {
  padding: 0 2rem 2rem;

  @media screen and (max-width: 599px) {
    padding: 0 1.5rem 1.5rem;
  }

  > div:nth-of-type(n+2) {
    margin-top: 1rem;
  }

  .index-list {
    margin: 0;
    padding: 0;
    border: 0;
  }

  .faq-list {
    margin: 0;
    padding-left: 1.25rem;

    li {
      // list-style: none;

      &:nth-of-type(n+2) {
        margin-top: .25rem;
      }
    }
  }
}


/**
 * On-Page Nav
 */
.entry-nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 1.2;

  @media screen and (max-width: 399px) {
    font-size: .875rem;
  }

}

.entry-nav__link {
  a {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &--prev {
    margin-right: auto;
    padding-right: .25rem;
  }

  &--next {
    margin-left: auto;
    padding-left: .25rem;
    text-align: right;
  }
}

.association-timestamp {
  margin-bottom: 1rem;
  font-weight: 700;
}

.download-link {
  margin-bottom: 2rem;
  column-count: 2;
  column-gap: 1rem;

  @media screen and (max-width: 799px) {
    column-count: auto;
    column-gap: 0;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  a {
    line-height: 1.2;
  }

  .download-link__icon {
    margin-right: .25rem;
    vertical-align: middle;
    position: relative;
    top: -4px;

    &.download-icon {
      top: -2px;
    }
  }
}

.battlescribe-link {
  margin-bottom: 2rem;

  a {
    line-height: 1.2;
  }

  .battlescribe-link__icon {
    margin-left: .25rem;
    vertical-align: middle;
    position: relative;
  }
}


/**
 * Posts
 */
.latest-header {
  margin-top: 3rem;
}

.post-date {
  margin-bottom: 1.5rem;
  font-style: italic;
  color: var(--gray-500);
}

.post-archive {
  margin-top: 3rem;

  & h3 {
    margin-bottom: 1rem;
  }

  &__list {
    padding-left: 0;

    li {
      list-style: none;
      line-height: 1.3;

      &:nth-child(n+2) {
        margin-top: 1rem;
      }
    }   

    .post-date {
      margin: 0;
      font-size: .9rem;
      display: block;
    }
  }
}

.post-nav {
  margin-right: .5rem;
  vertical-align: middle;
}



/**
 * Tables
 */

.rich-text-table,
.generic-table {
  margin: 2rem 0;
  border-collapse: collapse;
  border-radius: 0;
  box-shadow: none !important;

  body.dark-mode & {
    color: var(--gray-100);
  }

  tr {
    border-width: 0px;

    &:hover {
      body.dark-mode & td {
        background: var(--gray-800);
      }
    }
  }

  td,
  th {
    background: inherit;
    border-width: 1px !important;
    border-style: solid !important;
    border-color: var(--gray-300) !important;

    body.dark-mode & {
      color: var(--gray-400);
      border-color: var(--gray-700) !important;
    }

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }

    &.nowrap {
      white-space: nowrap;
    }

    &.rich-text-table__th {      
      background-color: var(--gray-100);

      body.dark-mode & {
        background: var(--gray-800);
      }
    }
  }

  .deep-link__icon {
    width: 16px;
    height: 16px;
  }
}

.generic-table {
  thead {
    tr {
      background: var(--gray-100);

      body.dark-mode & {
        background: var(--gray-800);
      }
    }

    th {
      font-size: .875rem;
      font-weight: 700;
    }
  }

  td,
  th {
    border-radius: 0 !important;
  }

  &.header-column tr td:first-child {
    font-weight: 700;
    background: var(--gray-100);

    body.dark-mode & {
      background: var(--gray-800);
    }
  }
}

.tow-reference-chart {
  thead,
  tbody {
    tr {
      &.unit td {
        border-top-width: 4px !important;
      }
    }

    th {
      white-space: nowrap;
    }

    th,
    td {
      &:not(:first-child):not(:last-child) {
        text-align: center;
        width: calc(50% / 9);
      }
    }
  }
}

/** TODO */
.tow-reference-chart-detailed {
  thead,
  tbody {
    th {
      white-space: nowrap;
    }

    th,
    td {
      min-width: 50px;
      text-align: center;

      @media screen and (max-width: 1199px) {
        min-width: 44px;
        padding: .4em .6em;
        font-size: 12px !important;
        line-height: 1.4 !important;
      }

      &.name,
      &.equipment,
      &.special-rules {
        text-align: left;
      }

      &.unit {
        text-align: left;
        font-weight: 700;
        background: var(--gray-300);

        body.dark-mode & {
          background: var(--gray-800);
        }
      }

      p {
        margin: 0;

        &:nth-child(n+2) {
          margin-top: 1em;
        }
      }

      ul {
        margin: 0;
        padding-left: 0;
        list-style: none;

        li:nth-child(n+2) {
          margin-top: 1em;
        }
      }
    }
  }
}

.chart-icon {
  margin-right: .25rem;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

.table-wrapper {
  margin: 2rem 0;
  padding: 1px;
  overflow-x: auto;
  overflow-y: hidden;

  @media screen and (max-width: 599px) {
    margin: 1rem 0;
  }

  @media print {
    margin: 1rem 0;
  }

  > table {
    margin: 0 !important;
  }

  &.centered {
    th,
    td {
      text-align: center;
    }
  }

  &.unit-profile {
    tr {
      td,
      th {
        width: 7%;
        min-width: 50px;
        text-align: center;

        &:first-child {
          text-align: left;
          width: 37%;
          white-space: nowrap;
        }
      }
    }
  }
}

.profile-table {
  td,
  th {
    text-align: center;
  }
}

.unit-profile-table {
  tr {
    td,
    th {
      width: 7%;
      min-width: 50px;
      text-align: center;

      &:first-child {
        text-align: left;
        width: 37%;
        white-space: nowrap;
      }
    }
  }
}



/**
 * To Wound Table
 */
.to-wound-chart,
.to-hit-chart {
  padding-top: 2rem !important;
  padding-left: 2rem !important;
  position: relative;

  &:before,
  &:after {
    content: "Target's Toughness";
    width: 100%;
    height: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: bold;
    text-align: center;
  }

  &:after {
    content: "Attack's Strength";
    transform-origin: top right;
    transform: rotate(-90deg);
    left: auto;
    right: 100%;
    width: 540px;
  }
}



/**
 * To Hit Chart
 */
.to-hit-chart {
  &:before {
    content: "Target's Weapon Skill";
  }

  &:after {
    content: "Attacker's Weapon Skill";
  }
}



/**
 * Weapon/Armour Profiles
 */
.profile-table {
  span {
    display: block;
    margin: 0;
  }

  td {  
    line-height: 1.4 !important;
  }

  span:nth-of-type(n+2) {
    margin-top: .25rem !important;

    @media screen and (max-width: 599px) {
      margin-top: .4rem !important;
    }
  }
}



/**
 * Spells
 */
.spell-list {
  margin-top: 3rem;

  @media screen and (max-width: 599px) {
    margin-top: 2rem;
  }

  .spell:nth-of-type(n+2) {
    margin-top: 3rem;
    padding-top: 2rem;
    border-top: 1px solid var(--gray-300);

    @media screen and (max-width: 599px) {
      margin-top: 2rem;
      padding-top: 1.5rem;
    }
  }
}

.spell__header {
  font-size: var(--font-size-2xl);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  line-height: 1.2;

  @media screen and (max-width: 599px) {
    font-size: var(--font-size-xl);
  }

  @media screen and (max-width: 399px) {
    font-size: 1.125rem;
    display: block;

    > span {
      display: block;
    }
  }

  > span:nth-of-type(2) {
    padding-left: 1rem;
    text-align: right;

    @media screen and (max-width: 399px) {
      padding-left: 0;
      text-align: left;
    }
  }
}

.spell__name {
  a {
    color: inherit;
    font: inherit;
  } 
}

.spell__details {
  span {
    display: inline-block;

    &:before {
      content: '\00a0';

      @media screen and (max-width: 399px) {
        content: '';
      }
    }

    &:after {
      content: ',';

      @media screen and (max-width: 399px) {
        content: '';
      }
    }

    &:first-of-type {
      &:before {
        content: '';
      }
    }

    &:last-of-type {
      &:after {
        content: '';
      }
    }    

    @media screen and (max-width: 399px) {
      display: block;
    }
  }
}



/**
 * Magic Items
 */
.magic-item-list {
  margin-top: 3rem;

  @media screen and (max-width: 599px) {
    margin-top: 2rem;
  }

  h2 {
    text-align: center;

    &:nth-of-type(n+2) {
       margin-top: 4rem;
      
      @media screen and (max-width: 599px) {
        margin-top: 3rem;
      }
    }

    + .magic-item {
      margin-top: 0 !important;
      padding-top: 0 !important;
      border-top: none !important;
    }
  }

  .magic-item {
    &:nth-of-type(n+2) {
      margin-top: 3rem;
      padding-top: 2rem;
      border-top: 1px solid var(--gray-300);

      @media screen and (max-width: 599px) {
        margin-top: 2rem;
        padding-top: 1.5rem;
      }
    }
  }
}

.magic-item__header {
  font-size: var(--font-size-2xl);
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  line-height: 1.2;

  @media screen and (max-width: 599px) {
    font-size: var(--font-size-xl);
    display: block;
  }

  @media screen and (max-width: 399px) {
    font-size: 1.125rem;
  }
}

.magic-item__name {
  @media screen and (max-width: 599px) {
    display: block;
  }

  a {
    padding-right: .5rem;
    color: inherit;
    font: inherit;

    @media screen and (max-width: 599px) {
      padding-right: 0;
    }
  }
}

.magic-item__cost {
  padding-left: .5rem;
  text-align: right;
  min-width: 150px;

  @media screen and (max-width: 599px) {
    display: block;
    padding-left: 0;
    text-align: left;
    white-space: normal;
    max-width: none;
  }
}

.magic-item__type {
  font-weight: normal;
  white-space: nowrap;
  display: inline-block;

  @media screen and (max-width: 599px) {
    display: block;
  }
}



/**
 * Unit Profiles
 */
.unit-profile__details {
  margin-top: 2rem;

  > div,
  > article {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  > article {
    :last-child {
      margin-bottom: 0;
    }
  }

  .no-link {
    white-space: nowrap;
  }

  .unit-profile__details__link,
  .unit-profile__details--link-list .detailed-link,
  .unit-profile__details--link-list .no-link {
    @media screen and (max-width: 799px) {
      display: block;
    }
  }

  .unit-profile__details__link:after,
  .unit-profile__details--link-list .detailed-link:after,
  .unit-profile__details--link-list .no-link:after {
    content: ',\00a0';

    @media screen and (max-width: 799px) {
      content: none;
    }
  }

  .unit-profile__details__link:last-child:after,
  .unit-profile__details--link-list .detailed-link:last-child:after,
  .unit-profile__details--link-list .no-link:last-child:after {
    content: none;
  }

  .unit-profile__details--list {
    > p:first-of-type {
      margin-bottom: 0;
    }
    ul {
      margin-top: 0;
      padding-left: 1.125rem;
    }
  }
}

.unit-profile__details--equipment,
.unit-profile__details--option {
  > p {
    margin-bottom: 0;
  }
  > ul {
    margin: 0;
    padding-left: 1.25rem;

    ul {
      margin: 0;

      li {
        margin: 0;
      }
    }
  }
}

.unit-section .index-list {
  margin-top: 0;
}

.unit-profile__details--points,
.unit-profile__details--option, {
  display: none;

  body.show-options & {
    display: block;
  }
}



/**
 * Forms
 */
.form__wrapper {
  .form__input {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
}

div[data-test-id="cf-ui-modal-content"] .form__wrapper .form__input:last-of-type {
  margin-top: 0;
}

.bug-reporter,
.change-request {
  margin: 0 auto;
  max-width: 600px;

  @media screen and (max-width: 599px) {
    margin: 0 auto;
  }

  .found-bugs {
    border: 1px solid var(--red-400);
    background: var(--red-200);
    border-radius: 5px;
    padding: 1rem;
    color: var(--color-black)
  }

}

.form__wrapper--change-request {
  margin-top: 2rem;
}

span.option {
  color: var(--gray-600);
}



/**
 * Chapters
 */
.chapter-view {
  > section:first-child > :first-child {
    margin-top: 0;
  }

  h1 {
    /*font-size: var(--font-size-2xl);*/
    margin-top: 4rem;
  }

  h2 {
    margin-top: 2rem;
  }

  .spell__header > *,
  .magic-item__header > * {
    font-size: var(--font-size-xl);
  }
}



/**
 * FAQ
 */
.faq__wrapper {
  position: relative;
}

.faq__loader {
  position: absolute;
  width: 100%;
}

.faq__list.loading {
  opacity: .3;
}

.faq__wrapper--single .faq__group {
  margin-top: 2rem;
}

.faq__group {
  margin-top: 6rem;

  @media screen and (max-width: 599px) {
    margin-top: 4rem;
  }

  .faq {
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--gray-300);

    @media screen and (max-width: 599px) {
      margin-bottom: 1rem;
      padding-bottom: 1rem;
    }
  }
}

.faq__question {
  margin-top: 0;
  line-height: 1.3;
}

.faq__source {
  color: var(--gray-500);
  font-style: italic;
  font-size: .9rem;
  line-height: 1.2;
}

.faq-filter {
  position: relative;
}

.faq-filter__clear {
  position: absolute;
  z-index: 9999;
  top: .5rem;
  right: .5rem;
  transform: rotate(45deg);
  opacity: .5;
  transition: $global-transition;

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
}

.faq-filter__status {
  color: var(--gray-500);
  font-style: italic;
  /*font-size: .875rem;*/
}



/**
 * User Preferences
 */
body {
  &.hide-lore .section-intro,
  &.hide-pop .detailed-link svg,
  &.hide-notice .global-notice,
  &.hide-actions .page-actions {
    display: none !important;
  }
}

body.dark-mode {
  color: var(--gray-400);
  background: var(--gray-900);

  div[data-test-id="cf-ui-workbench"],
  div[data-test-id="cf-ui-modal"] {
    background: var(--gray-900);
  }

  div[data-test-id="cf-ui-modal"] {
    border: 1px solid var(--gray-700);
  }

  .main-header,
  footer {
    background: #0b131f;
  }

  .main-header h1 {
    color: var(--gray-300);
  }

  hr {
    border-top-color: var(--gray-700);
  }

  .article--rich-text u {
    background: var(--green-900);
  }

  select,
  input[type="text"],
  input[type="number"],
  textarea {
    background: var(--gray-400);
  }

  .placeholder {
    opacity: .2;
  }

  .main-content,
  .main-content label,
  .nav-header__section,
  div[data-test-id="cf-ui-modal-header"] h1,
  div[data-test-id="cf-ui-modal-header"] h2,
  div[data-test-id="cf-ui-modal-content"],
  div[data-test-id="cf-ui-modal"] label,
  div[data-test-id="cf-ui-modal"] label {
    color: var(--gray-400);
  }

  .main-header,
  div[data-test-id="cf-ui-modal-header"],
  .nav-header__section,
  .index-list,
  .additional-links,
  .main-index .index-list-by-association,
  .section-divider,
  .search-separator,
  .faq__group .faq {
    border-bottom-color: var(--gray-700);
  }

  div[data-test-id="cf-ui-modal-header"] button svg {
    fill: var(--gray-500);
  }

  .main-index .index-list-by-association {
    border-left-color: var(--gray-700);
  }

  .main-header > button {
    border-right-color: var(--gray-700);

    &:hover,
    &:active,
    &:focus {
      background: var(--gray-800);
      box-shadow: none !important;
    }
  }

  .spell-list .spell:nth-of-type(n+2),
  .magic-item-list .magic-item:nth-of-type(n+2),
  .faq__list .faq {
    border-top-color: var(--gray-700);
  }

  .modal-overlay {
    background-color: rgba(12,20,28,.9);
  }

  blockquote,
  .embedded-asset figcaption {
    background: var(--gray-600);
  }

  .page-actions {
    .page-actions__button {
      background: none;
      border-color: var(--gray-700);

      &:hover,
      &:active,
      &:focus {
        background-color: var(--gray-800);
        box-shadow: none !important;
      }

      svg {
        fill: var(--gray-500);
      }
    }
  }

  @media screen {
    .armylist {
      span {
        background-color: var(--gray-900) !important;
      }

      table, th, td {
        border-color: var(--gray-400) !important;
      }

      thead tr, {
        border-bottom-color: var(--gray-400) !important;
      }

      tbody tr:nth-child(even) {
        background-color: var(--gray-800) !important;

        td:before {
          border-bottom-color: var(--gray-800) !important;
        }
      }
    }
  }
}



/**
 * Printable Cards
 */
.printable-cards {
  .page-title {
    @media print {
      display: none;
    }
  }
}

.cards {
  margin: 0 auto;
  border-collapse: collapse;
  width: 6in;
  display: flex;
  flex-wrap: wrap;
}

.card {
  width: 3in;
  height: 5in;
  padding: .333in .25in .25in;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-color: #e1e1e1;
  border-style: solid;
  border-width: 0 1px 1px 0;
  text-align: left;
  overflow: hidden;

  &:nth-child(odd) {
    border-left-width: 1px;
  }

  &:nth-child(1),
  &:nth-child(2) {
    border-top-width: 1px;
  }
}

.card__bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-top: .125in solid #222;
}

.card__title {
  margin: 0 0 .0625in;
  font-size: 16px;
  line-height: 1.1;
  text-align: center;
  width: 100%;

  em {
    display: inline-block;
    font-weight: normal;
    white-space: nowrap
  }
}

.card__details {
  margin: 0 0 .0625in;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}

.card__rule {
  font-size: 10px;

  p,
  .table-wrapper {
    margin: 0 0 .125in;
    line-height: 1.2;
  }

  ul,
  ol {
    margin: 0 0 .125in;
    padding-left: 10px;
  }

  li p {
    margin: 0 0 .0625in;
  }

  &.small > * {
    font-size: 9px !important;
    margin-bottom: .0625in !important;
  }

  &.small td,
  &.small th {
    p:not(:last-child) {
      margin: 0 0 .0625in;
    }
  }
}

.card__attribute {
  margin-top: auto;
  font-style: italic;
  font-size: 8px;
  line-height: 1.2;

  strong {
    font-style: normal;
  }
}

.print-instructions {
  max-width: 600px;
  margin: 0 auto 2rem;
  text-align: center;
}

.print-instructions__button {
  margin-top: 1rem !important;
}

.card {
  table,
  table tr,
  table th,
  table td {
    min-width: 0 !important;
    margin: 0 0 .125in;
    padding: 0;
    font-size: 9px;
    line-height: 1.2;
    border-radius: 0 !important;
    box-shadow: none;
    border: 0;
    border-collapse: collapse;
    color: #000;
  }

  table {
    width: 100%;
  }

  table td,
  table th {
    border: 1px solid #000;
    padding: 1px;

    body.dark-mode & {
      border-color: var(--gray-400);
      color: var(--gray-400);
    }

    @media print {
      border-color: #000 !important;
      color: #000;
    }

    p {
      &:last-of-type {
        margin: 0;
      }
    }
  }
}

@media print {
  * {
    color: #000 !important;
  }

  body,
  html {
    font-size: 10px;
  }

  body,
  div[data-test-id="cf-ui-workbench"] {
    padding: 0 !important;
    margin: 0 !important;
    background: #fff !important;
  }

  div[data-test-id="cf-ui-workbench"] {
    overflow: auto;
    position: static;
    display: block;
  }

  header[data-test-id="cf-ui-workbench-header"] + div {
    display: block;
  }

  div[data-test-id="cf-ui-workbench"] header,
  .page-actions,
  .editor-actions,
  .army-list .page-title,
  .digital-rulebook .page-title,
  .entry-nav,
  footer {
    display: none !important;
  }

  .main-content,
  .main-content > div {
    padding: 0 !important;
    max-width: none !important;
  }

  .print-instructions {
    display: none;
  }

  .card:nth-child(4n) {
    page-break-after: always;
  }
}